import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Instructions from "./Pages/Instructions/Instructions";
import Form from "./Pages/Form/Form";
import FaceScan from "./Pages/FaceScan/FaceScan";
import Result from "./Pages/Result/Result";
import TryAgain from "./Components/TryAgain/TryAgain";
import Loader from "./Components/Loader/Loader";
import ExistResult from "./Pages/ExistResult/ExistResult";
import UploadSpeedTest from "./Components/UploadSpeedTest/UploadSpeedTest";
import SpeedError from "./Components/SpeedError/SpeedError";
import HomePage from "./Pages/Homepage";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Instructions />} /> */}
        <Route path="/" element={<HomePage />} />
        <Route path="/form" element={<Form />} />
        <Route path="/face-scan" element={<FaceScan />} />
        <Route path="/result" element={<Result />} />
        <Route path="/try-again" element={<TryAgain />} />
        <Route path="/exist-result" element={<ExistResult />} />
        <Route path="/loader" element={<Loader />} />
        <Route path="/upload-test" element={<UploadSpeedTest />} />
        <Route path="/speed-error" element={<SpeedError />} />
      </Routes>
    </Router>
  );
};

export default App;
