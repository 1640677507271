import React from "react";
import { useNavigate } from "react-router-dom";
import { Warning } from "@phosphor-icons/react";

const TryAgain = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const userId = searchParams.get("userid");
  const name = searchParams.get("name");
  const height = searchParams.get("height");
  const weight = searchParams.get("weight");
  const age = searchParams.get("age");
  const gender = searchParams.get("gender");

  const handleTryAgain = () => {
    navigate(
      `/face-scan?userid=${userId}&name=${name}&height=${height}&weight=${weight}&age=${age}&gender=${gender}`
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl p-8 shadow-xl max-w-md w-full text-center">
        <div className="mb-6">
          <Warning
            size={48}
            weight="duotone"
            className="text-yellow-500 mx-auto"
          />
        </div>
        <h1 className="text-2xl font-bold text-gray-900 mb-3">
          Network Issue Detected
        </h1>
        <p className="text-gray-600 mb-8">
          Your connection appears to be unstable. Please check your internet and
          try again.
        </p>
        <button
          onClick={handleTryAgain}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-3 px-6 rounded-full transition-colors"
        >
          Try Again
        </button>
      </div>
    </div>
  );
};

export default TryAgain;
