import React, { useState } from "react";

import WebcamCapture from "../../Components/WebcamCapture/WebcamCapture";

const FaceScan = () => {
  const [start, setStart] = useState(false);

  const handleCapture = (imageArray, avgFPS) => {
    setStart(!start);
    imageArray.forEach((imageSrc, index) => {
      const base64Image = imageSrc.split(",")[1];
    });
  };

  return (
    <>
      <WebcamCapture onCapture={handleCapture} />
    </>
  );
};

export default FaceScan;
