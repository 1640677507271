import React, { useState, useEffect } from "react";

const Loader = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const messages = [
    "Initializing facial scan parameters...",
    "Calibrating vital sign detection...",
    "Preparing biometric analysis tools...",
    "Setting up heart rate monitoring...",
    "Configuring respiratory rate detection...",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 2500);
    return () => clearInterval(intervalId);
  }, [messages.length]);

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen bg-slate-50">
      {/* Scanning animation */}
      <div className="relative w-32 h-32 mb-8">
        <div className="absolute w-32 h-32 border-4 border-blue-500 rounded-lg">
          {/* Scanning line animation */}
          <div className="absolute w-full h-1 bg-blue-400 top-0 animate-scan"></div>
        </div>

        {/* Corner accents */}
        <div className="absolute -top-2 -left-2 w-4 h-4 border-t-4 border-l-4 border-blue-500"></div>
        <div className="absolute -top-2 -right-2 w-4 h-4 border-t-4 border-r-4 border-blue-500"></div>
        <div className="absolute -bottom-2 -left-2 w-4 h-4 border-b-4 border-l-4 border-blue-500"></div>
        <div className="absolute -bottom-2 -right-2 w-4 h-4 border-b-4 border-r-4 border-blue-500"></div>

        {/* Pulse rings */}
        <div className="absolute inset-0 border-4 border-blue-300 rounded-lg animate-ping opacity-20"></div>
      </div>

      {/* Message display */}
      <div className="max-w-md px-6">
        <p className="text-blue-600 text-xl font-bold text-center animate-pulse">
          {messages[currentMessageIndex]}
        </p>
        <p className="text-blue-400 text-sm mt-4 text-center">
          Preparing advanced vital sign monitoring
        </p>
      </div>
    </div>
  );
};

export default Loader;
