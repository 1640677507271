import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Heart,
  CaretRight,
  User,
  Phone,
  Ruler,
  Scales,
  CalendarBlank,
} from "@phosphor-icons/react";

const getUploadSpeed = () => {
  if (navigator.connection && navigator.connection.downlink) {
    const downlinkSpeed = navigator.connection.downlink;
    const uploadSpeed = downlinkSpeed;
    return uploadSpeed.toFixed(2);
  }
  return 1;
};

const Form = () => {
  const [formData, setFormData] = useState({
    name: "",
    height: "",
    weight: "",
    age: "",
    gender: "",
    phoneNumber: "",
  });

  const navigate = useNavigate();
  const phoneNumberRef = useRef(null);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const validateForm = () => {
    const { name, height, weight, age, gender, phoneNumber } = formData;
    if (
      name.trim() === "" ||
      height.trim() === "" ||
      weight.trim() === "" ||
      age.trim() === "" ||
      gender.trim() === "" ||
      phoneNumber.trim() === ""
    ) {
      return false;
    }
    if (
      isNaN(parseFloat(height)) ||
      isNaN(parseFloat(weight)) ||
      isNaN(parseInt(age))
    ) {
      return false;
    }
    return true;
  };

  const handleClick = () => {
    const speed = getUploadSpeed();
    if (speed < 0.5) {
      navigate(`/speed-error?speed=${speed}&userid=${formData.phoneNumber}`);
    } else {
      if (validateForm()) {
        console.log(formData);
        navigate(
          `/face-scan?userid=${formData.phoneNumber}&name=${formData.name}&height=${formData.height}&weight=${formData.weight}&age=${formData.age}&gender=${formData.gender}`
        );
      } else {
        alert("Please fill in all fields");
      }
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gradient-to-b from-blue-50 to-white p-4">
      <div className="w-full max-w-md space-y-8 rounded-xl bg-white p-8 shadow-lg ring-1 ring-gray-100">
        <div className="space-y-4 text-center">
          <Heart size={48} weight="duotone" className="mx-auto text-rose-500" />
          <h1 className="text-3xl font-bold text-gray-900">VitalScan AI</h1>
          <p className="text-gray-600">
            Complete your profile for personalized analysis
          </p>
        </div>

        <div className="space-y-4">
          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <User size={20} className="text-gray-400" />
            </div>
            <input
              id="name"
              type="text"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter your name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <Ruler size={20} className="text-gray-400" />
            </div>
            <input
              id="height"
              type="number"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter your height (in cm)"
              value={formData.height}
              onChange={handleChange}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <Scales size={20} className="text-gray-400" />
            </div>
            <input
              id="weight"
              type="number"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter your weight (in kg)"
              value={formData.weight}
              onChange={handleChange}
            />
          </div>

          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <CalendarBlank size={20} className="text-gray-400" />
            </div>
            <input
              id="age"
              type="number"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter your age"
              value={formData.age}
              onChange={handleChange}
            />
          </div>

          <select
            id="gender"
            className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 px-4 text-gray-600 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            value={formData.gender}
            onChange={handleChange}
          >
            <option value="">Select your gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>

          <div className="relative">
            <div className="absolute inset-y-0 left-3 flex items-center">
              <Phone size={20} className="text-gray-400" />
            </div>
            <input
              id="phoneNumber"
              ref={phoneNumberRef}
              type="tel"
              className="w-full rounded-lg border border-gray-200 bg-gray-50 py-3 pl-10 pr-4 text-gray-600 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              placeholder="Enter your phone number"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
          </div>
        </div>

        <button
          onClick={handleClick}
          className="group relative flex w-full items-center justify-center space-x-2 rounded-lg bg-gradient-to-r from-blue-500 to-blue-600 px-4 py-3 text-white shadow-lg transition-all hover:from-blue-600 hover:to-blue-700"
        >
          <span>Continue</span>
          <CaretRight
            size={20}
            weight="bold"
            className="transition-transform group-hover:translate-x-1"
          />
        </button>

        <p className="text-center text-xs text-gray-500">
          Your privacy is our priority. All information is encrypted and secure.
        </p>
      </div>
    </div>
  );
};

export default Form;
